jQuery(document).ready(function () {
    showContent();
    swapContent();
    opacityChange();
    mehrVibesScrollTop();
    toggleFaq();
    search();
    mobileOpener();
    signUp();
    redirect();
    quickViewReplacer();
    topScrollUp();
    productContentSwitcher();
    frontPageNav();
    shopViewChanger();
    copyToClipboard();
    wishlist();
    buttonTextWishList();
    mevolysLoginValidation();
    mevolysRegisterValidation();
    viewPassword();
    replacer();
    removeLink();
});

function showContent() {

    jQuery(".icon-gelb").click(function () {
        if (jQuery(".das-sind-main--gelb-container").is(":hidden")) {
            jQuery(".das-sind-main--rot-container, .das-sind-main--pink-container, .das-sind-main--blau-container, .das-sind-main--türkis-container,.das-sind-main--lila-container").hide();
            jQuery(".das-sind-main--gelb-container").fadeIn("slow");
        }
    });
    jQuery(".icon-pink").click(function () {
        if (jQuery(".das-sind-main--pink-container").is(":hidden")) {
            jQuery(".das-sind-main--rot-container, .das-sind-main--gelb-container, .das-sind-main--blau-container, .das-sind-main--türkis-container,.das-sind-main--lila-container").hide();
            jQuery(".das-sind-main--pink-container").fadeIn("slow");
        }
    });
    jQuery(".icon-blau").click(function () {
        if (jQuery(".das-sind-main--blau-container").is(":hidden")) {
            jQuery(".das-sind-main--rot-container, .das-sind-main--pink-container, .das-sind-main--gelb-container, .das-sind-main--türkis-container,.das-sind-main--lila-container").hide();
            jQuery(".das-sind-main--blau-container").fadeIn("slow");
        }
    });
    jQuery(".icon-rot").click(function () {
        if (jQuery(".das-sind-main--rot-container").is(":hidden")) {
            jQuery(".das-sind-main--blau-container, .das-sind-main--pink-container, .das-sind-main--gelb-container, .das-sind-main--türkis-container,.das-sind-main--lila-container").hide();
            jQuery(".das-sind-main--rot-container").fadeIn("slow");
        }
    });

    jQuery(".icon-lila").click(function () {
        if (jQuery(".das-sind-main--lila-container").is(":hidden")) {
            jQuery(".das-sind-main--blau-container, .das-sind-main--pink-container, .das-sind-main--gelb-container, .das-sind-main--türkis-container,.das-sind-main--rot-container").hide();
            jQuery(".das-sind-main--lila-container").fadeIn("slow");
        }
    });

    jQuery(".icon-türkis").click(function (height) {
        if (jQuery(".das-sind-main--türkis-container").is(":hidden")) {
            jQuery(".das-sind-main--blau-container, .das-sind-main--pink-container, .das-sind-main--gelb-container, .das-sind-main--lila-container,.das-sind-main--rot-container").hide();
            jQuery(".das-sind-main--türkis-container").fadeIn("slow");
        }
    });
}

function swapContent() {
    jQuery(".item-gelb").click(function () {
        jQuery(".excerpt-blau, .excerpt-pink, .excerpt-türkis, .excerpt-rot, .excerpt-lila").hide();
        jQuery(".das-sind-main").hide();
        jQuery(".excerpt-gelb").fadeIn();
    });

    jQuery(".item-blau").click(function () {
        if (jQuery(".excerpt-blau").is(":hidden")) {
            jQuery(".excerpt-gelb, .excerpt-pink, .excerpt-türkis, .excerpt-rot, .excerpt-lila").hide();
            jQuery(".das-sind-main").hide();
            jQuery(".excerpt-blau").fadeIn();
        }
    });

    jQuery(".item-pink").click(function () {
        if (jQuery(".excerpt-pink").is(":hidden")) {
            jQuery(".excerpt-gelb, .excerpt-blau, .excerpt-türkis, .excerpt-rot, .excerpt-lila").hide();
            jQuery(".das-sind-main").hide();
            jQuery(".excerpt-pink").fadeIn();
        }
    });

    jQuery(".item-türkis").click(function () {
        if (jQuery(".excerpt-türkis").is(":hidden")) {
            jQuery(".excerpt-gelb, .excerpt-pink, .excerpt-blau, .excerpt-rot, .excerpt-lila").hide();
            jQuery(".das-sind-main").hide();
            jQuery(".excerpt-türkis").fadeIn();
        }
    });

    jQuery(".item-rot").click(function () {
        if (jQuery(".excerpt-rot").is(":hidden")) {
            jQuery(".excerpt-gelb, .excerpt-pink, .excerpt-türkis, .excerpt-blau, .excerpt-lila").hide();
            jQuery(".das-sind-main").hide();
            jQuery(".excerpt-rot").fadeIn();
        }
    });

    jQuery(".item-lila").click(function () {
        if (jQuery(".excerpt-lila").is(":hidden")) {
            jQuery(".excerpt-gelb, .excerpt-pink, .excerpt-türkis, .excerpt-rot, .excerpt-blau").hide();
            jQuery(".das-sind-main").hide();
            jQuery(".excerpt-lila").fadeIn();
        }
    });
}

function opacityChange() {

    let item = jQuery(".more-vibes-item");

    item.click(function () {
        let thisItem = $(this);
        thisItem.addClass('active');
        item.not(thisItem).removeClass('active');
    });
}

function mehrVibesScrollTop() {
    jQuery(".icon-gelb-up, .icon-pink-up, .icon-blau-up, .icon-rot-up, .icon-türkis-up, .icon-lila-up").click(function () {
        jQuery("html, body").animate({scrollTop: 150}, "slow");
        setTimeout(function () {
            jQuery(".das-sind-main--rot-container, .das-sind-main--blau-container, .das-sind-main--pink-container, .das-sind-main--gelb-container, .das-sind-main--türkis-container,.das-sind-main--lila-container").hide();
        }, 1000)
        return false;
    });
}

function toggleFaq() {
    jQuery(".js-faq-title").on("click", function (e) {
        e.preventDefault();
        let jQuerythis = jQuery(this);
        if (!jQuerythis.hasClass("faq__active")) {
            jQuery(".js-faq-content").slideUp(800);
            jQuery(".js-faq-title").removeClass("faq__active");
            jQuery('.js-faq-rotate').removeClass('faq__rotate');
        }
        jQuerythis.toggleClass("faq__active");
        jQuerythis.next().slideToggle();
        jQuery('.js-faq-rotate', this).toggleClass('faq__rotate');
    });
}

function search() {
    jQuery('#mevolys-search-bar, #mevolys-search-bar-2').click(function () {
        jQuery('#searchBoxMevoly, #searchBoxMevoly-2').fadeToggle("slow");
    });
}

function mobileOpener() {
    jQuery('.menuBtn').click(function () {
        jQuery(this).toggleClass('act');
        if (jQuery(this).hasClass('act')) {
            jQuery('.main-menu-2, .logged-menu-1, .logged-menu, .admin-menu, .admin-menu-1').css("display", "block").addClass('act');
        } else {
            jQuery('.main-menu-2, .logged-menu-1, .logged-menu, .admin-menu, .admin-menu-1').css("display", "none").removeClass('act');
        }
    });
    jQuery('.start-menuBtn').click(function () {
        jQuery(this).toggleClass('act');
        if (jQuery(this).hasClass('act')) {
            jQuery('.main-menu,.main-menu-2, .logged-menu, .admin-menu').css("display", "block").addClass('act');
        } else {
            jQuery('.main-menu,.main-menu-2, .logged-menu, .admin-menu').css("display", "none").removeClass('act');
        }
    });
}


function signUp() {
    jQuery('.register').click(function () {
        jQuery('.woocommerce .col2-set .col-1').hide();
        jQuery('.woocommerce .col2-set .col-2').css('display', 'block');
        jQuery('.col2-set .col-2').fadeIn();
    });

    jQuery('#reg-close').click(function () {
        jQuery('.woocommerce .col2-set .col-1').show('0.02seconds');
        jQuery('.col2-set .col-2').fadeOut();
        jQuery('.woocommerce .col2-set .col-2').fadeOut();
    });
}

function redirect() {
    jQuery('.hinweis-icon, .disclaimer-bild, .disclaimer, .notice-2-icon').click(function () {
        location.href = "disclaimer/";
    });
}

function quickViewReplacer() {
    let imageUrl = "/wp-content/themes/mevolys/images/icons/Produkte/Detailansicht.svg";
    let replacedElements = jQuery('.woosq-btn');
    replacedElements.css("background-image", "url(" + imageUrl + ")");
    replacedElements.css("background-size", "contain")
    replacedElements.css("background-repeat", "no-repeat")
}

function addToCartReplacer() {
    let imageUrl = "/wp-content/themes/mevolys/images/icons/Produkte/Warenkorb.svg";
    let replacedElements = jQuery('.add_to_cart_button');
    replacedElements.css("background-image", "url(" + imageUrl + ")");
    replacedElements.css("background-size", "contain")
    replacedElements.css("background-repeat", "no-repeat")
}


function topScrollUp() {
    const toTopBtn = ['<a href="#top" class="scroll-top-btn"></a>'].join("");
    jQuery("body").append(toTopBtn)
    jQuery(".scroll-top-btn").hide();
    jQuery(function () {
        jQuery(window).scroll(function () {
            if (jQuery(this).scrollTop() > 200) {
                jQuery('.scroll-top-btn').fadeIn();
            } else {
                jQuery('.scroll-top-btn').fadeOut();
            }
        });

        jQuery('.scroll-top-btn').click(function () {
            jQuery('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });
}

function shopViewChanger() {
    jQuery('.flex-view-icon').on('click', function () {
        location.reload();
    });

    jQuery('.list-view-icon').on('click', function () {
        let clicked = true;
        if (clicked) {
            jQuery('.product').css({
                'width': '100%',
                'display': 'grid',
                'grid-template-columns': '1fr',
                'align-items': 'center',
                'justify-items': 'center'
            });
            jQuery('.product a').css({
                'display': 'grid',
                'grid-template-columns': '1fr 7fr ',
                'align-items': 'center',
                'justify-items': 'center'
            });
            jQuery('.shopengine-icon-add_to_favourite_1').css({
                'position': 'relative',
                'top': '40px',
                'left': '265%'
            });
            jQuery('.woosq-btn').css({
                'position': 'relative',
                'top': '-40px',
                'left': '8%'
            });
            jQuery('.add_to_cart_button').css({
                'position': 'relative',
                'top': '-10px'
            });
            jQuery('.woocommerce-loop-product__title').css({
                'margin-right': '5.5em'
            });
            jQuery('.price').css({
                'margin-top': '-81px',
            });
            jQuery('.category-name-display').css({
                'margin-left': '2em',
            });
        }
    });
}

function productContentSwitcher() {
    jQuery('#product-menu').on('click', 'a', function () {
        jQuery('.current').not(jQuery(this).closest('li').addClass('current')).removeClass('current');
        jQuery('.product-content:visible').hide();
        jQuery('.product-content[id=' + jQuery(this).attr('data-id') + ']').fadeIn('fast');
    });
}

function frontPageNav() {
    const home_header = jQuery('.home-header');
    if (home_header.length) {
        let actualBottom = home_header.offset().top + home_header.outerHeight(true);
        jQuery(document).scroll(function () {
            let y = jQuery(this).scrollTop();
            if (y > actualBottom) {
                jQuery('.hidden-menu-frontpage').fadeIn("slow");
            } else {
                jQuery('.hidden-menu-frontpage').fadeOut("slow");
            }
        });
    }
}

function copyToClipboard() {
    jQuery('#copy-btn').click(function () {
        let copyText = jQuery("#mentor-number-precise");
        copyText.select();
        document.execCommand("copy");
        alert("Erfolgreich kopiert");
    });
}

function wishlist() {
    let product = jQuery('.table.table-bordered.shopengine-wishlist tr th:contains("Products")');
    let unitPrice = jQuery('.table.table-bordered.shopengine-wishlist tr th:contains("Unit Price")');
    let stockStatus = jQuery('.table.table-bordered.shopengine-wishlist tr th:contains("Stock Status")');
    let action = jQuery('.table.table-bordered.shopengine-wishlist tr th:contains("Action")');
    let inStock = jQuery('.table.table-bordered.shopengine-wishlist tr td:contains("In Stock")').css("color", "green");
    let outOfStock = jQuery('.table.table-bordered.shopengine-wishlist tr td:contains("outofstock")').css("color", "red");
    product.text('Produkt');
    unitPrice.text('Stückpreis');
    stockStatus.text('Verfügbarkeit');
    action.text('Aktion');
    inStock.text('Vorrätig');
    outOfStock.text('Ausverkauft');
}

function buttonTextWishList() {
    jQuery('.woosq-btn').html('.');
    jQuery('.button.product_type_simple.add_to_cart_button.ajax_add_to_cart').html('.');
}


function mevolysLoginValidation() {
    jQuery('form[id="mevolys-login-form"]').validate({
        rules: {
            username: 'required',
            password: 'required',
        },
        messages: {
            username: 'Dieses Feld ist erforderlich',
            password: 'Dieses Feld ist erforderlich',
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
}

function mevolysRegisterValidation() {

    jQuery('.register_form_row_submit').fadeOut();
    /* Mentoren ID Check */
    jQuery('#custom_mentor_number').on('change', function () {

        jQuery.ajax({
            type: 'POST',
            url: ajax.url,
            dataType: 'json',
            data: {
                action: 'checkMentorNumber',
                key: jQuery(this).val()
            },
            success: function (data, textStatus, XMLHttpRequest) {

                if(data == '1') {
                    jQuery('#errorMentorNumber').html('<span style="color: green;">Deine angegebene Mentoren-Nummer ist gültig!</span>');
                    jQuery('.register_form_row_submit').fadeIn();
                } else {
                    jQuery('.register_form_row_submit').fadeOut();
                    jQuery('#errorMentorNumber').html('<span style="color: red;">Bitte gebe eine gültige Mentoren-Nummer an!</span>');
                }

                jQuery('#errorMentorNumber').fadeIn();
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log(errorThrown);
            }
        });
    });

    jQuery.validator.addMethod("custom_email", function(value, element) {
        return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);
    }, "Eingabe einer gültigen E-Mail");

    jQuery('#custom_registration_form').validate({

        rules: {
            custom_user_first: 'required',
            custom_user_last: 'required',
            custom_mentor_number: 'required',
            custom_user_register: 'required',
            custom_user_email: {
                required: true,
                email: false,
                custom_email: true
            },
            custom_user_email_confirm: {
                required: true,
                email: false,
                custom_email: true,
                equalTo: "#custom_user_email",
            },
            custom_user_pass: {
                required: true,
                minlength: 8,
            },
            custom_user_pass_confirm: {
                required: true,
                equalTo: "#user_password"
            }
        },
        messages: {
            custom_user_first: 'Dieses Feld ist erforderlich',
            custom_user_last: 'Dieses Feld ist erforderlich',
            custom_mentor_number: 'Dieses Feld ist erforderlich',
            custom_user_register: 'Dieses Feld ist erforderlich',
            custom_user_email: {
                required: 'Dieses Feld ist erforderlich',
                custom_email: 'Eingabe einer gültigen E-Mail',
            },
            custom_user_email_confirm: {
                required: 'Dieses Feld ist erforderlich',
                custom_email: 'Die E-Mail Adressen stimmen nicht überein',
                equalTo: 'Die E-Mail Adressen stimmen nicht überein'
            },
            custom_user_pass: {
                required: 'Dieses Feld ist erforderlich',
                minlength: 'Das Passwort muss mindestens 8 Zeichen lang sein'
            },
            custom_user_pass_confirm: {
                required: 'Dieses Feld ist erforderlich',
                equalTo: 'Kennwort stimmt nicht überein'
            },
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    jQuery("#custom_registration_form").on("submit", function (form) {
        if (!jQuery("#agree_checkbox").prop("checked")) {
            jQuery("#agree_chk_error").show();
        } else {
            jQuery("#agree_chk_error").hide();
        }
        return false;
    })
}

function viewPassword() {
    jQuery(".toggle-password").click(function () {
        jQuery(this).toggleClass("fa-eye fa-eye-slash");
        let input = jQuery(jQuery(this).attr("toggle"));
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });
}

function replacer() {
    jQuery(".wc-gzd-checkbox-placeholder.wc-gzd-checkbox-placeholder-legal").attr("id", "gzd-checkbox-grabber");
}

function removeLink() {
    jQuery('.category-name-display a:nth-child(2)').removeAttr('href');
}
